import { Component, Vue } from 'vue-property-decorator';
import to from 'await-to-js';
import BasePage from '@/models/BasePage';
import { userService } from '@/main';

@Component
export default class InviteClaimView extends BasePage {
    public passwordRepeat: string = '';
    public password: string = '';

    public invite: any = null;

    public async mounted() {
        const [err, response] = await to(userService.getInvite(this.$route.params.key));
        if (err) {
            this.showFailedResponse('Invalid invite key', err);
            return this.$router.push({ name: 'login' });
        }

        this.invite = response.data;
    }

    public async claimInvite() {
        if (this.password !== this.passwordRepeat) {
            return this.showValidationErrors(['Wachtwoorden zijn niet gelijk aan elkaar']);
        }

        this.showPending('De uitnodiging accepteren..');

        const [err] = await to(userService.claimInvite(this.$route.params.key, this.password));
        if (err) {
            this.showFailedResponse('Failed to claim invite', err);
        }

        this.clearAndShowSuccess('Uitnodiging geclaimd, wachtwoord ingesteld. Je kunt nu inloggen');
        return this.$router.push({ name: 'login' });
    }
}
